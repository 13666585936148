<template>
    <div class="deck">
        <div ref="imageWrapper" class="container preview">
            <div class="image-box simple-card" v-for="(item, k) in list" v-bind:key="k"
                 :class="{'has-image':item.image}"
                 :style="item.style">
                <template v-if="item.type === 'image' && item.displayImage">
                    <div class="view-box" :style="item.contentStyle">
                        <div class="view-image" :style="`background-image:url(${item.displayImage})`"></div>
                    </div>
                </template>
            </div>
        </div>
        
        <div class="container">
            <div class="move-box simple-card" v-for="(image, k) in list" v-bind:key="k"
                 :style="image.style"
                 @click.stop="deckImageClicked(image)"
                 :class="{ 'has-border': image === currentImage }">
                <div @click.stop="deleteImage(image)" class="img-delete">
                </div>
                
                <transition name="fadeIn">
                    <div class="waite-box" v-if=" (currentImage && image !== currentImage) ">
                        <img src="/assets/icons/scheme-waite.png" alt="">
                        <span>放置到该区域</span>
                    </div>
                </transition>
            </div>
        </div>
        
        <div class="clearfix"></div>
        
        <div class="deck-tools">
            <div class="tool-item" @click="horizontalFlip(currentImage)">
                <img src="/assets/images/deck/tool-icon7.png">
                <span>垂直翻转</span>
            </div>
            <div class="tool-item" style="margin: 0;" @click="verticalFlip(currentImage)">
                <img src="/assets/images/deck/tool-icon8.png">
                <span>水平翻转</span>
            </div>
        </div>
        
        
        <div v-if="showPreviewModal" class="preview-modal full-screen">
            <div @click="showPreviewModal = false" class="preview-bg full-screen fadeIn"></div>
            <div class="preview-img fadeInDownBig">
                <button title="关闭" @click="showPreviewModal = false" class="preview-btn iconfont pc-guanbi"></button>
                
                <template v-if="tplLoading">
                    <p>搭配图片生成中，请稍候~</p>
                    <loader/>
                </template>
                <template v-else>
                    <img :src="previewImage">
                </template>
            </div>
        </div>
        
        <drawer v-model="templateContainerActive">
            <deck-template mode="simple" @selected="deckTplSelected"/>
        </drawer>
    
    </div>
</template>

<script>
import html2canvas from "html2canvas"
import Vue from 'vue';
import Loader from "@/components/Loader";
import Drawer from "@/components/Drawer";
import DeckTemplate from "./DeckTemplate";
import {GoodsElement} from './GoodsElement';

export default {
    name: "image-deck-simple",
    components: {DeckTemplate, Drawer, Loader},
    props: {
        images: Array,
        goodsList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentImage: null,
            html2canvasOptions: {
                backgroundColor: null,
                proxy: 'https://api.ycsd.work/api/collocation/image/proxy',
            },
            list: [],
            previewImage: null,
            showPreviewModal: false,
            templateContainerActive: false,
            tplLoading: false,
            frames: {
                "width": "600",
                "height": "600",
                "goods": [
                    {"x": 0, "y": 0, "w": 394, "h": 394},
                    {"x": 414, "y": 0, "w": 186, "h": 186},
                    {"x": 414, "y": 207, "w": 186, "h": 186},
                    {"x": 414, "y": 414, "w": 186, "h": 186},
                    {"x": 0, "y": 414, "w": 186, "h": 186},
                    {"x": 207, "y": 414, "w": 186, "h": 186}
                ]
            },
        }
    },
    watch: {
        images() {
            this.reloadList()
        }
    },
    mounted() {
        window.mounted = true;
        this.loadFrames();
        this.reloadList();
        
        console.clear();
        
    },
    methods: {
        active() {
            //
        },
        addRotateStyle(img) {
            
            let style = img['contentStyle'] || {}
            
            if (!img.rotate) {
                
                delete style['transform']
                
            } else if (img.rotate === 1) {
                
                style['transform'] = 'rotateX(180deg)';
                
            } else if (img.rotate === 2) {
                
                style['transform'] = 'rotateY(180deg)';
                
            } else if (img.rotate === 3) {
                
                style['transform'] = 'rotateZ(180deg)';
                
            }
            
            Vue.set(img, 'contentStyle', style)
            
            this.$forceUpdate()
        },
        build(callback) {
            
            let arr = []
            
            this.list.forEach(item => {
                if (item.type === 'image') arr.push(item)
            })
            
            html2canvas(this.$refs.imageWrapper, this.html2canvasOptions).then(canvas => {
                
                let img = canvas.toDataURL("image/png")
                
                callback(img, arr)
                
            });
            
        },
        cleanSelect() {
            this.currentImage = null;
        },
        async createImageItem(image, className) {
            const goods = new GoodsElement()
            await goods.init(image, {
                maxWidth: 200,
                maxHeight: 200,
                class: className,
            })
            return goods
        },
        deckImageClicked(image) {
            
            if (!this.currentImage && !image.image) return;
            
            if (this.currentImage === image) {
                this.currentImage = null;
                return;
            }
            
            if (this.currentImage === null) {
                this.currentImage = image;
                return;
            }
            
            const currentImage = this.currentImage;
            
            this.currentImage = null
            
            setTimeout(() => {
                
                let currentStyle = currentImage.style;
                
                currentImage.style = image.style;
                
                image.style = currentStyle
                
            }, 500)
        },
        deckTplSelected(tpl) {
            
            this.templateContainerActive = false
            
            this.tplData = tpl;
            
            this.loadTplData();
        },
        deleteImage(img) {
            let index = this.list.indexOf(img);
            
            this.currentImage = null;
            
            if (index === -1) return
            
            this.list.splice(index, 1, this.emptyItem(img))
            
            this.$emit('deleted', img.image)
        },
        emptyItem(img) {
            return {
                type: 'image',
                image: '',
                style: img.style,
            }
        },
        getFrames() {
            let arr = []
            
            this.list.forEach(item => {
                if (item.type === 'image') arr.push(item)
            })
            
            return arr
        },
        getGoods(image) {
            return this.goodsList.find(goods => goods.white_image === image)
        },
        getImageData() {
            return this.list;
        },
        horizontalFlip(img) {
            if (!img) return
            
            if (img.rotate === 1) {
                
                img.rotate = 0;
                
            } else if (img.rotate === 2) {
                
                img.rotate = 3
                
            } else if (img.rotate === 3) {
                
                img.rotate = 2
                
            } else {
                
                img.rotate = 1
                
            }
            
            this.addRotateStyle(img)
        },
        loadFrames() {
            
            const frame = this.frames
            
            const x = (number) => {
                return number * (600 / frame.width);
            }
            
            const y = (number) => {
                return (number / (600 / frame.height));
            }
            
            this.list = []
            
            frame.goods.forEach((g) => {
                
                this.list.push({
                    type: 'image',
                    image: '',
                    style: {
                        left: x(g.x) + 'px',
                        top: y(g.y) + 'px',
                        width: x(g.w) + 'px',
                        height: y(g.h) + 'px',
                    },
                });
                
            })
        },
        loadTplData() {
            
            const tpl = this.tplData;
            
            if (!tpl || (!tpl.frame)) return;
            
            this.frames = tpl.frame;
            
            this.loadFrames();
            
            if (this.images && this.images.length > 0) {
                this.reloadList()
            }
        },
        preview() {
            
            this.showPreviewModal = true
            this.tplLoading = true
            
            setTimeout(() => {
                
                html2canvas(this.$refs.imageWrapper, this.html2canvasOptions).then(canvas => {
                    
                    this.$swal.close()
                    
                    this.previewImage = canvas.toDataURL("image/png");
                    
                    this.tplLoading = false
                    
                });
                
            }, 50)
        },
        reloadList() {
            
            if (!this.images || !this.images.length) {
                this.loadFrames();
                return
            }
            
            if (!this.list || !this.list[0]) return
            
            console.clear()
            console.log(this.images.length);
            
            this.images.map(async (item) => {
                
                let inbox = false
                
                for (let i = 0; i < this.list.length; i++) {
                    
                    const listElement = this.list[i]
                    
                    if (listElement.image === item) {
                        
                        console.log('in box', listElement.image)
                        
                        inbox = true
                        
                        continue
                    }
                    
                    if (listElement.image && this.images.indexOf(listElement.image) === -1) {
                        
                        this.list.splice(i, 1, this.emptyItem(listElement))
                        
                        console.log('delete');
                        
                        return
                    }
                }
                
                if (inbox) return;
                
                const className = Number.parseInt(this.getGoods(item)?.yc_cate_id)
                
                const goods = await this.createImageItem(item, className);
                const idx = this.list.findIndex((item) => !item.image)
                
                
                if (idx < 0) {
                    this.$emit('deleted', item)
                    return;
                }
                
                const oldListItem = this.list[idx]
                
                goods._setStyle(
                    parseInt(oldListItem.style.left),
                    parseInt(oldListItem.style.top),
                    parseInt(oldListItem.style.width),
                    parseInt(oldListItem.style.height),
                )
                
                this.list.splice(idx, 1, goods)
                
            })
            
            
        },
        setFrames(frames) {
            this.list = frames
            this.$forceUpdate()
        },
        setImageData(data) {
            data.forEach(async (item) => {
                
                let obj = await this.createImageItem(item.image);
                
                if (obj) {
                    obj.style = item.style;
                    obj.contentStyle = item.contentStyle;
                    this.list.push(obj);
                }
            })
        },
        showTemplate() {
            this.templateContainerActive = true;
        },
        verticalFlip(img) {
            if (!img) return
            
            if (img.rotate === 2) {
                
                img.rotate = 0;
                
            } else if (img.rotate === 1) {
                
                img.rotate = 3;
                
            } else if (img.rotate === 3) {
                
                img.rotate = 1
                
            } else {
                img.rotate = 2
            }
            
            this.addRotateStyle(img)
        },
    },
    computed: {},
}

</script>

<style lang="less" scoped>
.deck {
    position: relative;
    display: block;
    height: 648px;
}

.container {
    width: 600px;
    height: 600px;
    background-color: transparent;
    padding: 0;
    line-height: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.move-box {
    position: absolute;
    background-size: 100% auto !important;
    user-select: none;
    cursor: pointer;
    border-radius: 20px;
    
}

.move-box.has-border {
    opacity: 1;
    border: 1px solid #333333;
    padding: 0;
    
    .img-delete {
        display: block;
    }
}

.img-delete {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -8px;
    right: -8px;
    cursor: pointer;
    color: white;
    line-height: 12px;
    text-align: center;
    display: none;
    background-image: url("/assets/icons/scheme-del-icon-v2.png");
    background-size: 100%;
    
    .iconfont {
        font-size: 12px;
        color: white;
    }
}


.hidden-color .move-box {
    border-color: transparent;
}

.hidden-color .img-scale:after {
    border-color: transparent;
}

.preview {
    border: 0;
}

.image-box {
    overflow: hidden;
    position: absolute;
    transition: all .3s ease-in-out;
    user-select: none;
    
    &.has-image {
        background-image: none;
    }
}

.view-box {
    width: 100%;
    height: 100%;
}

.view-image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.deck-tools {
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    
    .tool-item {
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        
        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        
        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.tool-btn {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    
    span {
        font-size: 12px;
        margin-right: 8px;
    }
    
    img {
        line-height: 0;
        vertical-align: middle;
        margin-right: 8px;
        width: 14px;
    }
}

.preview-btn,
.template-btn {
    width: 32px;
    height: 32px;
    background-color: white;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 101;
    font-size: 12px;
}

.template-btn {
    top: 64px;
    
    img {
        width: 12px;
    }
}

.preview-modal {
    z-index: 102;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-bg {
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.preview-img {
    width: 600px;
    height: 600px;
    background-color: #F5F5F5;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    img {
        width: 600px;
        height: 600px;
    }
    
    .loading-gif {
        width: 48px;
        height: 48px;
    }
}

.image-box {
    background-color: #fff;
    border-radius: 20px;
    background-image: url("/assets/icons/scheme-simple-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.waite-box {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
    line-height: 12px;
    flex-direction: column;
    border-radius: 20px;
    
    img {
        width: 24px;
        margin-bottom: 4px;
    }
}
</style>